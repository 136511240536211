<template>
  <div>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        style="z-index:1000 !important"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'bonus-alert-success' : 'bonus-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <router-view :cmsBonusCategoryId="searchCriteria.bonusCategoryId" @updateFilterType="updatePromotionFilterType"></router-view>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { CACHE_KEY } from '@/constants/constants'
import { uiHelper, locale } from '@/util'
import { CMS_CATEGORIES_LIST } from '../../store/cms.module'

export default {
  name: 'promotionLayout',
  // metaInfo: {
  //   title: `${locale.getMessage('meta.promotion_title')}`,
  //   titleTemplate: `%s`,
  //   meta: [{charset: 'utf-8'}, {
  //     name: 'description',
  //     content: `${locale.getMessage('meta.promotion_description')}`
  //   }, {name: 'keyword', content: `${locale.getMessage('meta.promotion_keyword')}`}],
  //   link: [
  //     {rel: 'canonical', href: `https://JAYA9.com/promotion/`}
  //   ]
  // },

  data: () => ({
    currentLanguage: uiHelper.getLanguage(),
    hideVendorSelection: false,
    routeName: ROUTE_NAME,
    searchCriteria: {
      bonusCategoryId: 0,
      pageNumber: 1,
      pageSize: 20
    },
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    cmsBonusCategories() {
      return this.$store.state.cms.cmsBonusCategories
    },
    translatedBonusCategories() {
      let categories = this.$store.state.cms.cmsBonusCategories
      return categories.map(c => {
        const localizedText = c.localization.filter(l => l.hasOwnProperty(this.currentLanguage))[0]
        if (localizedText) {
          return {
            name: localizedText[this.currentLanguage],
            id: c.id
          }
        }
        return {
          name: c.name,
          id: c.id
        }
      })
    }
  },
  watch: {
    alertShow() {
      if (!this.alertShow) {
        this.alertSuccess = ''
        this.alertMessage = ''
      }
    },
    $route() {
      this.hideVendorSelection = this.$route.name == 'promotion-history' || this.$route.name == 'promotion-in-progress'
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    }
  },
  created() {
    this.initHideVendorSelection()
    this.listBonusCategories()
    this.metaTag()
  },
  methods: {
    metaTag() {
      uiHelper.setMetaTag('url', window.location.origin + '/promotion/')
      uiHelper.setTitle(`${locale.getMessage('meta.promotion_title')}`)
      uiHelper.setMetaTag('title', `${locale.getMessage('meta.promotion_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.promotion_description')}`)
      uiHelper.setCanonical('https://www.jaya9.app/promotion/')
    },
    updatePromotionFilterType(type) {
      this.searchCriteria.gameType = type
    },
    initHideVendorSelection() {
      this.hideVendorSelection = this.$route.name == 'promotion-history' || this.$route.name == 'promotion-in-progress'
    },
    listBonusCategories() {
      this.$store.dispatch(`${CMS_CATEGORIES_LIST}`, { currencyCode: uiHelper.getCurrency() })
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    }
  }
}
</script>

<style lang="scss"></style>
